/* eslint-disable no-unused-vars */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";

// Actions
import * as app from "actions/app";
// react-router-dom components
import { useLocation, NavLink, Link } from "react-router-dom";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

import * as CryptoUtil from "utils/CryptoUtil";

// @mui material components
import { Button, OutlinedInput, FormControl, Typography, IconButton } from "@mui/material";

import FilterListIcon from "@mui/icons-material/FilterList";
import SettingsIcon from "@mui/icons-material/Settings";

import InputAdornment from "@mui/material/InputAdornment";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { ArrowDropDown } from "@mui/icons-material";

// @ Mui icons
import SearchIcon from "@mui/icons-material/Search";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiBadge from "components/sui/SuiBadge";
import SuiPrice from "components/sui/SuiPrice";
// Soft UI Dashboard PRO React example components
import SidenavCard from "components/layout/Sidenav/SidenavCard";
import CurrencyMenu from "components/layout/CurrencyMenu";
import BasicMenu from "components/layout/Sidenav/FilterDropdown";

import SidenavRoot from "components/layout/Sidenav/SidenavRoot";
import WalletManagement from "components/layout/WalletManagement";

// Custom styles for the Sidenav

import sidenavLogoLabel from "components/layout/Sidenav/styles/sidenav";
import ColorButton from "components/layout/Sidenav/styles/index";

// Soft UI Dashboard PRO React context
import { useSoftUIController, setMiniSidenav } from "context";

import getSymbolFromCurrency from "currency-symbol-map";
import Constants from "constants/Constants";

const BigNumber = require("bignumber.js");

const countries = Constants.CURRENCIES_COUNTRIES_CODES;

// eslint-disable-next-line react/prop-types
function Sidenav({ color, brand, brandName, routes, changeSetting, dispatch: execute, ...rest }) {
  const [controller, dispatch] = useSoftUIController();
  const location = useLocation();

  // select data from store
  const assets = useSelector((state) => (state.appStore.assets ? state.appStore.assets : {}));

  const balance = useSelector((state) => state.appStore.balance);
  const { miniSidenav, transparentSidenav } = controller;
  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const [selectCurrencyDropdown, setSelectCurrencyDropdown] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [currency, setCurrency] = useState(
    useSelector((state) => state.appStore.settings.defaultCurrency)
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [search, setSearch] = useState("");
  const [showSortingMenu, setShowSortingMenu] = useState(false);
  const [sortingType, setSortingType] = useState(
    useSelector((state) => state.appStore.settings.sortingType)
  );
  const hiddenItems = useSelector((state) => state.appStore.settings.hiddenItems);
  const showZeroBalance = useSelector((state) => state.appStore.settings.showZeroBalance);
  const showTokens = useSelector((state) => state.appStore.settings.showTokens);
  const exchangeRates = useSelector((state) => state.appStore.exchangeRates);
  const [settingsToggle, setSettingsToggle] = useState(false);
  const [order, setOrder] = useState(() => {
    if (sortingType === "CURRENCY") {
      return Object.keys(assets).sort((key1, key2) =>
        assets[key1].symbol.localeCompare(assets[key2].symbol)
      );
    }
    if (sortingType === "ESTIMATES") {
      return Object.keys(assets).sort((key1, key2) =>
        assets[key1].name.localeCompare(assets[key2].name)
      );
    }
    return Object.keys(assets).sort((key1, key2) =>
      assets[key1].symbol.localeCompare(assets[key2].symbol)
    );
  });

  // filter
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setShowSortingMenu(true);
  };

  const handleSelectCurrency = (newCurrency) => {
    setSelectCurrencyDropdown(false);
    setAnchorEl(null);
    if (newCurrency) {
      setCurrency(newCurrency);
      changeSetting("defaultCurrency", newCurrency);
    }
  };

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    if (sortingType) {
      changeSetting("sortingType", sortingType);
      if (sortingType === "CURRENCY") {
        // eslint-disable-next-line no-unused-vars
        setOrder(
          Object.keys(assets).sort((key1, key2) => {
            if (assets[key1].type === "IBAN") return false;
            if (assets[key2].type === "IBAN") return true;
            return assets[key1].symbol.localeCompare(assets[key2].symbol);
          })
        );
      } else if (sortingType === "ESTIMATES") {
        setOrder(
          Object.keys(assets).sort((key1, key2) =>
            assets[key1].name.localeCompare(assets[key2].name)
          )
        );
      } else if (sortingType === "RATING") {
        setOrder(Object.keys(assets));
      }
    }
  }, [sortingType]);

  const CRYPTO_PREFIX = "/crypto/";

  const path = window.location.pathname;
  const name = path.replace(CRYPTO_PREFIX, "");

  let sum = new BigNumber(0);
  Object.keys(assets).map((key) => {
    const value = CryptoUtil.toAmount(
      balance[key],
      assets[key].decimals,
      2,
      exchangeRates && exchangeRates.exchanges[key]
        ? exchangeRates.exchanges[key].price[currency]
        : 0
    );
    sum = sum.plus(value);

    return true;
  });
  sum = sum
    .precision(7)
    .toFixed(2)
    .toString(10)
    .replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, "$1");
  let hiddenWallets = 0;
  let wallets = Object.keys(assets).length;
  const renderlistItem = order.map((key) => {
    const hide = hiddenItems.includes(key);
    const item = assets[key];
    if (
      !hide &&
      (search === "" ||
        item.name.toUpperCase().includes(search) ||
        item.symbol.toUpperCase().includes(search)) &&
      (showZeroBalance || item.balance > 0) &&
      (showTokens || !item.token)
    )
      return (
        <Link to={`/crypto/${key}`} className="crypto__link">
          <SuiBox
            key={item.name}
            component="li"
            display="flex"
            mb={1}
            alignItems="center"
            className={`crypto__list-items ${!hide ? "hide-crypto-item" : ""} ${
              key.toLowerCase() === name.toLowerCase() ? "active-crypto-item" : ""
            }`}
          >
            <SuiBox mr={2} position="relative">
              {item.icon && (
                <SuiBox
                  className="d-inline-block item-icon-wrapper"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: item.icon }}
                  mx={miniSidenav ? 2 : 0}
                />
              )}
            </SuiBox>
            {!miniSidenav && (
              <SuiBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="center"
              >
                <SuiTypography className="crypto-item-title" fontSize="0.875rem" fontWeight="bold">
                  {item.name}
                  <SuiBadge
                    badgeContent={item.symbol}
                    variant="contained"
                    size="sm"
                    color="light"
                    container
                    className="badge_top"
                    fontWeight="bold"
                  />
                </SuiTypography>
                <SuiBox
                  className="crypto-sub-title-block"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  pt={0.1}
                >
                  {item.subicon && (
                    <SuiBox
                      className="d-inline-block item-icon-wrapper"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: item.subicon }}
                      mx={miniSidenav ? 2 : 0}
                    />
                  )}
                  {item.subtitle && (
                    <SuiTypography ml={1} className="crypto-sub-title" color="dark">
                      {item.subtitle}
                    </SuiTypography>
                  )}
                </SuiBox>
              </SuiBox>
            )}

            <SuiBox
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "center",
                marginLeft: "auto",
              }}
              className="crypto-price-qty"
            >
              {!miniSidenav && (
                <SuiBox
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  <SuiPrice name={key} />
                </SuiBox>
              )}
              {!item.test && (
                <SuiBox
                  sx={{
                    display: `${!miniSidenav ? "block" : "none"} `,
                    fontSize: "14px",
                  }}
                >
                  {getSymbolFromCurrency(currency)} <SuiPrice name={key} currency={currency} />
                </SuiBox>
              )}
            </SuiBox>
          </SuiBox>
        </Link>
      );
    hiddenWallets += 1;
    wallets -= 1;
    return null;
  });

  return (
    <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, miniSidenav }}>
      {settingsToggle && <WalletManagement setSettingsToggle={setSettingsToggle} />}
      <SuiBox pt={2} pb={0} px={4} textAlign="center">
        <SuiBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <SuiTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </SuiTypography>
        </SuiBox>
        <SuiBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <SuiBox component="img" src={brand} alt={brandName} width="3rem" />}
          <SuiBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <SuiTypography component="h6" variant="button" fontWeight="medium">
              {brandName}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      </SuiBox>
      <Divider />
      {showSearchInput ? (
        <SuiBox
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={1}
          bgColor="white"
          borderRadius="6px"
          sx={{ boxShadow: "rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem" }}
        >
          <FormControl sx={{ width: "19ch" }}>
            <OutlinedInput
              onChange={(e) => setSearch(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              flex={1}
            />
          </FormControl>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              setShowSearchInput(false);
              setSearch("");
            }}
          >
            Cancel
          </Button>
        </SuiBox>
      ) : (
        <SuiBox
          bgColor="white"
          borderRadius="6px"
          sx={{ boxShadow: "rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem" }}
        >
          <SuiBox py={1.5} px={3} className="topbox_header">
            <SuiBox
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              position="relative"
            >
              <SuiBox display="flex">
                <SuiTypography variant="h4" color="dark" fontWeight="bold" mr={1}>
                  {sum}
                </SuiTypography>
                {!miniSidenav && (
                  <ColorButton
                    variant="contained"
                    endIcon={<ArrowDropDown />}
                    disableElevation
                    onClick={(event) => {
                      setSelectCurrencyDropdown(true);
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    {currency}
                  </ColorButton>
                )}

                {selectCurrencyDropdown && !miniSidenav && (
                  <SuiBox sx={{ position: "absolute", zIndex: 2, top: "2.5rem", left: "1.5rem" }}>
                    <CurrencyMenu
                      currencies={countries}
                      anchorEl={anchorEl}
                      close={handleSelectCurrency}
                    />
                  </SuiBox>
                )}
              </SuiBox>
              {!miniSidenav && (
                <SuiBox
                  backgroundColor="red"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      setShowSearchInput(true);
                      setSelectCurrencyDropdown(false);
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                  <IconButton aria-label="delete" onClick={handleClick}>
                    <FilterListIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      setSettingsToggle(true);
                    }}
                  >
                    <SettingsIcon />
                  </IconButton>
                </SuiBox>
              )}
            </SuiBox>
            {!miniSidenav && (
              <SuiBox
                sx={{
                  marginTop: "4px",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "row",
                  position: "absolute",
                  flexWrap: "nowrap",
                }}
              >
                <Typography
                  sx={{ fontWeight: "700", fontSize: "0.65rem", color: "rgb(103, 116, 142)" }}
                >
                  {wallets} wallets
                </Typography>
                <Typography
                  mx={1}
                  sx={{ fontWeight: "700", fontSize: "0.65rem", color: "rgb(103, 116, 142)" }}
                >
                  |
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "0.65rem",
                    color: "rgb(103, 116, 142)",
                  }}
                >
                  {hiddenWallets} hidden
                </Typography>
              </SuiBox>
            )}
            {showSortingMenu && (
              <BasicMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                setSortingType={setSortingType}
                sortingType={sortingType}
              />
            )}
          </SuiBox>
        </SuiBox>
      )}
      <SuiBox mt={2}>{renderlistItem}</SuiBox>
      <SuiBox pt={2} my={2} mx={0}>
        <SidenavCard />
      </SuiBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  changeSetting: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  changeSetting: (name, value, callback) => dispatch(app.changeSetting(name, value, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidenav);
