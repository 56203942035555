/* eslint-disable no-shadow */
import ActionTypes from "constants/ActionTypes";
import Constants from "constants/Constants";

import * as app from "actions/app";

const { TRANSACTION_STATUSES } = Constants;
const { TRANSACTION_TYPES } = Constants;

export function sendTransaction(asset, transaction, callback) {
  return (dispatch, getState) => {
    try {
      switch (transaction.type) {
        case TRANSACTION_TYPES.SEND:
          getState().appStore.assets[asset].web3.eth.sendTransaction(
            { ...transaction },
            (error, result) => {
              if (result) {
                dispatch({
                  type: ActionTypes.WEB3_SEND_TRANSACTION,
                  payload: {
                    asset,
                    transaction: { ...transaction, id: result },
                  },
                });
              }
              callback(error, result);
            }
          );

          break;
        case TRANSACTION_TYPES.TRANSFER_TOKEN:
          getState()
            .appStore.assets[asset].erc20.methods.transfer(
              transaction.to,
              transaction.value.toString()
            )
            .send(
              {
                from: transaction.from,
                gasPrice: transaction.gasPrice,
                gas: transaction.gasLimit,
              },
              (error, result) => {
                if (result) {
                  dispatch({
                    type: ActionTypes.WEB3_SEND_TRANSACTION,
                    payload: {
                      asset,
                      transaction: { ...transaction, id: result },
                    },
                  });
                }
                callback(error, result);
              }
            );

          break;
        default:
          dispatch({
            type: ActionTypes.WEB3_ERROR_TRANSACTION,
            payload: {
              asset,
              error: { code: 500, error: "Unknown transaction type" },
            },
          });
      }
    } catch (error) {
      callback(error);
    }
  };
}

export function sendToken(asset, transaction, callback) {
  return (dispatch, getState) => {
    try {
      const { mydappwallet } = window;
      const { erc20 } = getState().appStore.assets[asset];
      erc20.methods
        .transfer(transaction.to, transaction.value)
        .send(
          { from: transaction.from, gasPrice: transaction.gasPrice, gas: transaction.gasLimit },
          (error, result) => {
            if (result) {
              mydappwallet.transaction({ uid: result }, (error, result) => {
                callback(error, result);
                if (result)
                  dispatch({
                    type: ActionTypes.WEB3_SEND_TRANSACTION,
                    payload: {
                      asset,
                      transaction: { ...transaction, id: result },
                    },
                  });
                else
                  dispatch({
                    type: ActionTypes.WEB3_ERROR_TRANSACTION,
                    payload: {
                      asset,
                      error,
                    },
                  });
              });
            } else {
              dispatch({
                type: ActionTypes.WEB3_ERROR_TRANSACTION,
                payload: {
                  asset,
                  error,
                },
              });
            }
          }
        );
    } catch (error) {
      error.code = 500;
      dispatch({
        type: ActionTypes.WEB3_ERROR_TRANSACTION,
        payload: {
          asset,
          error,
        },
      });
      callback(error);
    }
  };
}

export function confirmTransaction(asset, code, callback) {
  return (dispatch, getState) => {
    try {
      const { mydappwallet } = window;
      const transaction = getState().web3Store.transactions[asset];
      mydappwallet.confirm({ id: transaction.id, code }, (error, result) => {
        callback(error, result);
        if (result) {
          dispatch({
            type: ActionTypes.WEB3_CONFIRM_TRANSACTION,
            payload: {
              asset,
              transaction: { ...transaction, hash: result, status: TRANSACTION_STATUSES.PENDING },
            },
          });
          let intervalId;
          const interval = () => {
            mydappwallet.transaction({ hash: result }, (error, result) => {
              if (result && result.status !== TRANSACTION_STATUSES.PENDING) {
                dispatch({
                  type: ActionTypes.WEB3_UPDATE_TRANSACTION,

                  payload: {
                    asset,
                    transaction: result,
                  },
                });
                dispatch(app.balance(asset));
                clearInterval(intervalId);
              }
            });
          };
          intervalId = setInterval(interval, 1000);
        } else {
          mydappwallet.transaction({ id: transaction.id }, (error, result) => {
            if (result) {
              dispatch({
                type: ActionTypes.WEB3_UPDATE_TRANSACTION,

                payload: {
                  asset,
                  transaction: result,
                },
              });
            } else {
              dispatch({
                type: ActionTypes.WEB3_UPDATE_TRANSACTION,
                payload: {
                  asset,
                  transaction,
                },
              });
            }
          });
        }
      });
    } catch (error) {
      callback(error);
    }
  };
}

export function clearTransaction(asset) {
  return {
    type: ActionTypes.WEB3_CLEAR_TRANSACTION,
    payload: {
      asset,
    },
  };
}

export function rejectTransaction(asset, values, callback) {
  return (dispatch) => {
    try {
      const { mydappwallet } = window;

      // eslint-disable-next-line no-unused-vars
      mydappwallet.reject(values, (error, result) => {
        dispatch(clearTransaction(asset));
        callback(error, result);
      });
    } catch (error) {
      error.code = 500;
      dispatch(clearTransaction(asset));
      callback(error);
    }
  };
}
